.custom-slider-container {
    position: relative;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    padding: 40px 0;
  }
  
  .custom-slider {
    display: flex;
    transition: transform 0.5s ease-in-out;
    max-width: 1440px;
  }
  
  .slide {
    flex: 0 0 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
  }
  
  .slide.active {
    opacity: 1;
  }
  
  .slide-content {
    width: 45%;
    height: 300px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    background-color: #f0f0f0;
  }
  
  .slide-content h2 {
    font-size: 24px;
    font-weight: bold;
  }
  
  .slide-content p {
    font-size: 16px;
    margin-top: 10px;
    line-height: 24px;
  }
  
  .rating {
    display: flex;
  }
  
  .rating svg {
    color: gold;
    width: 20px;
    height: 20px;
  }
  .slide-image{
   width: 45%;
  }
  .slide-image img {
    width: 100%;
  }
  
  .slider-dots {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }
  
  .dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #bbb;
    margin: 0 5px;
    cursor: pointer;
  }
  
  .dot.active {
    background-color: #333;
  }
  
  @media (max-width:1000px){
    .slide-content {
      width: 49%;
      height: 300px;
      padding: 15px;
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
      background-color: #f0f0f0;
    }
    
    .slide-content h2 {
      font-size: 18px;
      font-weight: bold;
    }
    
    .slide-content p {
      font-size: 14px;
      margin-top: 5px;
      line-height: 24px;
    }
    
    .rating {
      display: flex;
    }
    
    .rating svg {
      color: gold;
      width: 20px;
      height: 20px;
    }
  }
  @media (max-width:750px){
    .slide{
      flex-direction: column;
    }
    .custom-slider {
      display: flex;
      transition: transform 0.5s ease-in-out;
      gap: 0.5rem;
      max-width: 1440px;
      margin: 30px 0;
    }
    .slide-content p {
      font-size: 12px;
    }
    .slide-image{
      width: 100%;
    }
    .slide-content{
      width: 100%;
    }
    .slider-dots {
      margin: 30px 0
    }
    .custom-slider-container{
      height: auto;
    }
  }