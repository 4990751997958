.serf{
    display: flex;
    gap: 2.5rem;
    justify-content: center;
    align-items: center;
    padding: 80px 0;
}

.serf .center {
    transform: scale(1.2);
}

@media (max-width:955px){
    .serf{
        gap: 2rem;
        padding: 40px 0;
    }
    .serf  img{
        width: 170px;
    }
}
@media (max-width:670px){
    .serf{
        gap: 1.5rem;
        padding: 30px 0;
    }
    .serf  img{
        width: 130px;
    }
}
@media (max-width:530px){
    .serf{
        gap: 1rem;
        padding: 20px 0;
    }
    .serf  img{
        width: 100px;
    }
}
@media (max-width:430px){
    .serf  img{
        width: 70px;
    }
}