.about-content-1{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 50px 0;
    text-align: center;
}
.about-cont{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 20px;
}
.about-left{
    width: 45%;
}
.about-left img {
    width: 100%;
}
.about-right{
    width: 45%;
    display: flex;
    flex-direction: column;
    gap: 3rem;
    text-align: left;
}
.about-right p{
    line-height: 24px;
}

@media (max-width:1000px){
    .about-right p{
        font-size: 12px;
    }
}
@media (max-width:700px){
    .about-cont{
        flex-direction: column;
    }
    .about-left{
        width: 100%;
    }
    .about-right{
        width: 100%;
        text-align: center;
    }
    .about-right button{
        margin: 0 auto;
    }
    .about-right p{
        font-size: 10px;
    }
}