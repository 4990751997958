.textile {
  display: flex;
  flex-direction: column;
  padding: 30px 0;
  text-align: center;
}
.textile h1 {
  padding-bottom: 30px;
}
.textile-content {
  display: flex;
  justify-content: space-between;
}
.textile-left {
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 40%;
  gap: 2rem;
}
.textile-left p {
  font-weight: 100;
  line-height: 22px;
}
.textile-right {
  width: 50%;
}
.customer {
  display: flex;
  justify-content: space-between;
  width: 80%;
  margin: 50px auto;
}
.cust-box {
  display: flex;
  flex-direction: column;
  padding: 15px 0;
  width: 30%;
  gap: 0.5rem;
  border-radius: 10px;
  box-shadow: 0 0 10px #000;
}
.cust-box h1 {
  padding: 0;
}
.cust-box p {
  font-weight: lighter;
}
@media (max-width: 1220px) {
  .textile-left p {
    font-weight: lighter;
    font-size: 12px;
  }
  .textile-right iframe {
    width: 300px;
    height: 300px;

  }
}
@media (max-width: 820px) {
  .textile-left p {
    font-weight: lighter;
    font-size: 10px;
    line-height: 16px;
  }
}
@media (max-width: 690px) {
  .textile-left p {
    font-weight: lighter;
    font-size: 10px;
    line-height: 16px;
  }
  .textile-content {
    flex-direction: column;
    text-align: center;
    gap: 1rem;
  }
  .textile-left {
    width: 100%;
  }
  .textile-left h3 {
    text-align: center;
  }
  .textile-left p {
    text-align: center;
  }
  .textile-left button {
    margin: 0 auto;
  }
  .textile-right {
    width: 100%;
  }

  .cust-box h1 {
    padding: 0;
    font-size: 20px;
  }
  .cust-box p {
    font-size: 10px;
  }
}
@media (max-width: 500px) {
.textile{
    padding-top: 10px;
}
  .customer {
    flex-direction: column;
    gap: 1rem;
    margin: 10px auto;
  }
  .cust-box {
    width: 100%;
  }
}
@media (max-width: 400px) {
  .textile-right iframe {
    width: 200px;
    height: 200px;
  }
}
