/* css/banner.css */
.banner-container {
    position: relative;
    width: 100%;
    height: 300px;
    background: linear-gradient(to bottom, #007bff, #ffffffa6);
    padding-top: 100px;
    color: #fff;
    
  }
  .banner-content {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #dcdcdc66;
    border-radius: 20px 20px  0 0;
    padding: 30px;
    width: 300px;
    margin: 0 auto;
    height: 100%;
    animation: circel 2s infinite;
  }
  
  .banner-content h1 {
    opacity: 0;
    animation: fadeIn 2s infinite;
    font-size: 2.5rem;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @keyframes circel {
    0% {
      border-radius: 20px 20px 0 0;
    }
    50% {
      border-radius: 50%  50%  0 0;

    }
    100%{
      border-radius: 20px 20px 0 0;
    }
  }

  @media (max-width:450px){
   .banner-content{
    width: 200px;
   }
   .banner-content h1 {
    font-size: 1.5rem;
  }
  }