.hcatalog {
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 30px 0;

}
.directory-container {
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
  }
  
  .filter-list {
    display: flex;
    justify-content: left;
    list-style: none;
    padding: 0;
  }
  
  .filter-list li {
    text-align: left;
    cursor: pointer;
    padding: 8px 12px;
    margin: 0 5px;
    border-radius: 4px;
    color: #000;
    transition: color 0.3s ease;
  }
  
  .filter-list li.active {
    color: #007bff;
  }
  
  .image-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    margin-top: 20px;
  }
  
  .image-item img {
    width: 100%;
    height: auto;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  @media (max-width:750px){
    .image-container {
      grid-template-columns: repeat(2, 1fr);
    }
    .filter-list li{
      font-size: 12px;
    }
  }
  @media (max-width:400px){
    .filter-list li{
      font-size: 6px;
    }
  }