*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
  font-family: Arial, Helvetica, sans-serif;
}

.container{
  width: 90%;
  margin: 0 auto;
  max-width: 1440px;
}
a{
  color: inherit;
}
.mark{
  color: #217DE9;
}
.btn{
  padding:15px 25px;
  border: 0;
  border-radius: 10px;
  font-size: 1.2rem;
  font-weight: 500;
  background: linear-gradient(to right, #217DE9, black);
  color: aliceblue;
  width: 200px;
}
.btn:hover{
  background: linear-gradient(to left, #217DE9, black);
  transition: 0.5s;
  transform: scale(1.05);
}
.title{
  text-align: center;
  margin: 0 auto;
}