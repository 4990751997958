/* contact.css */

/* Style for the contact container */
.contact-container {
    padding: 40px;
    background-color: #f0f0f0;
  }
  
  /* Style for the contact content */
  .contact-content {
    max-width: 500px;
    margin: 0 auto;
    padding: 20px;
  }
  
  /* Style for the contact form */
  .contact-content form {
    display: flex;
    flex-direction: column;
    width: 500px;
    margin: 0 auto;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 10px;
  }
  
  .contact-content h1 {
    font-weight: bold;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .contact-content div {
    margin-bottom: 5px;
  }
  .contact-content input,
  .contact-content textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: inset  5px 5px 10px rgba(0, 0, 0, 0.4);
  }
  
  .contact-content textarea {
    resize: vertical;
    height: 100px;
  }
  
  .contact-content button[type="submit"] {
    background-color: #fff;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    transition: background-color 0.3s ease;
  }
  
  .contact-content button[type="submit"]:hover {
    background-color: #0056b3;
  }
  .form-f{
    display: flex;
    gap: 1rem;
    justify-content: space-between;
  }
   .form-f div{
    width: 49%;
   } 
  .form-f input{
    width: 100%;
  }

  .adress{
    padding: 30px 0;
    display: flex;
    justify-content: space-between;
  }
  .adress-box{
    background: linear-gradient(to bottom, #217DE9, #fff);
    width: 300px;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 50%;
    gap: 1rem;
  }
  .adress-box p{
    font-size: 10px;
    width: 150px;
    text-align: center;
    opacity: 0.8;
  }
  .adress-box span i {
    font-size: 2rem;
    color: #fff;
  }
  .f-box{
    display: flex;
    flex-direction: column;
  }

  @media (max-width:1000px){
    .adress-box{
      width: 200px;
      height: 200px;
    }
    .adress-box p{
      width: 100px;
      font-size: 8px;
    }
  }
  @media (max-width:660px){
    .adress{
      flex-direction: column;
      gap: 1rem;
    }
    .adress-box{
      margin: 0 auto;
      width: 200px;
      height: 200px;
    }
    .adress-box p{
      width: 100px;
      font-size: 8px;
    }
    .contact-content form {
      width: 300px;
    }
  }
  @media (max-width:400px){
    .adress{
      flex-direction: column;
      gap: 1rem;
    }
    .adress-box{
      margin: 0 auto;
      width: 200px;
      height: 200px;
    }
    .adress-box p{
      width: 100px;
      font-size: 8px;
    }
    .contact-content form {
      width: 200px;
    }
    .contact-content form  button{
      width: 100px;
    }
    .form-f{
      flex-direction: column;
      gap: 0;
    }
    .form-f div{
      width: 100%;
     }
  }