/* catalog.css */

.catalog-container {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding: 50px 0;
}

.category-filter {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: space-between;
  align-items: left;
  padding: 10px;
}

.category-list, 
.filter-list {
  list-style: none;
  display: flex;
  padding: 0;
  margin: 0;
}

.category-list li,
.filter-list li {
  padding: 5px 10px;
  cursor: pointer;
  color: #000;
}

.category-list li.active,
.filter-list li.active {
  color: #007bff;
}

.catalog-images {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
  margin-top: 20px;
}

.catalog-item img {
  width: 300px;
  height: 400px;
  border-radius: 5px;
}


@media (max-width:1400px){
  .catalog-item img {
    width: 100%;
    height: 330px;
  }
}
@media (max-width:1000px){
  .catalog-item img {
    height: 270px;
  }
}
@media (max-width:800px){
  .catalog-item img {
    height: 220px;
  }
}
@media (max-width:680px){
  .catalog-images {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
    margin-top: 10px;
  }
  .catalog-item img {
    height: auto;
  }
}