.services{
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 40px 0;
}
.services-content{
    display: flex;
    justify-content: space-between;
    width: 90%;
    margin: 30px auto;
}
.serv-box{
    width: 300px;
    height: 300px;
    background: linear-gradient(to bottom, #217DE9, #fff);
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}
.icon i{
    font-size: 3rem;
    color: #fff;
}
.serv-box h4{
    color: #fff;
    padding-bottom: 30px;
}
.serv-box p{
    font-size: 13px;
    width: 200px;
}
.serv-box button{
    border: 0;
    background: 0;
    font-size: 1rem;
    font-weight: 600;
}
@media(max-width:1100px){
    .serv-box{
        width: 200px;
        height: 200px;
    }
    .icon i{
        font-size: 2rem;
        color: #fff;
    }
    .serv-box h4{
        padding-bottom: 1px;
        font-size: 14px;
    }
    .serv-box p{
        font-size: 9px;
        width: 150px;
    }
    .serv-box button{
        font-size: 12px;
    }
    .services h1{
        font-size: 24px;
    }
    .services h3{
        font-size: 20px; 
    }
}
@media(max-width:750px){
    .services-content{
  flex-direction: column;
  gap: 1rem;
    }
    .serv-box{
        margin: 0 auto;
    }
}