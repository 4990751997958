.company{
    display: flex;
    justify-content: space-between;
    padding: 20px 0;
    width: 100%;
}
.company img{
    width: 150px;
    height: auto;
    object-fit: contain;
}
@media (max-width:880px){
    .company img{
        width: 100px;
    }
}
@media (max-width:580px){
    .company img{
        width: 70px;
    }
}
@media (max-width:400px){
    .company {
        flex-direction: column;
        gap: 1rem;
    }
    .company img{
        width: 25%;
        margin: 0 auto;
    }
}