/* css/footer.css */
.footer {
    background: linear-gradient(100deg, #217DE9, #000);
    color: #fff;
    padding: 20px;
  }
  
  .footer-content {
    display: flex;
    flex-direction: column;
  }
  .footer-logo{
    width: 25%;
  }
  .footer-logo img{
    width: 300px;
  }
  .footer-top{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .footer p {
    margin: 0;
  }
  
  .footer-links {
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 25%;
    align-items: center;

  }
  
  .footer-links li {
    display: inline;
    font-size: 1.5rem;
  }
  
  .footer-links a {
    color: #fff;
    text-decoration: none;
  }
  
  .footer-links a:hover {
    text-decoration: underline;
  }
  .footer-icon{
    width: 25%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    text-align: center;
    gap: 1rem;
  }
  .footer-icon span{
    border-radius: 50%;
    background-color: #fff;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    margin: 0 auto;
    justify-content: center;
  }
  .footer-icon span i{
    color: #217DE9;
  }
  .copy{
    text-align: center;
    padding: 20px 0 0 0;
    border-top: 2px solid #fff;
  }

  @media (max-width:780px){
    .footer-links li {
      font-size: 0.8rem;
    }
    .footer-logo img{
      width: 200px;
    }
    .footer-icon span{
      width: 25px;
      height: 25px;

    }
  }
  @media (max-width:550px){
    .footer-top{
      flex-direction: column;
      gap: 1rem;
    }
    .footer-links li {
      font-size: 0.8rem;
    }
    .footer-logo{
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .footer-logo img{
      width: 50%;
      margin: 0 auto;
    }
    .footer-icon {
      grid-template-columns: repeat(5, 1fr);
      width: 100%;
      padding-bottom: 20px;
    }
    .copy{
      text-align: center;
      padding: 20px 0 0 0;
      font-size: 12px;
    }
  }