/* nav.css */
.logo {
    width: 50px;
  }
  
  .logo2 {
    display: none;
    width: 40px;
  }
  
  nav {
    background-color: #000;
    position: relative;
    z-index: 999;
  }
  
  .nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0;
  }
  
  .nav ul {
    display: flex;
    gap: 1.2rem;
  }
  
  .nav ul li {
    font-weight: 600;
    color: #fff;
  }
  
  .nav ul li:hover {
    color: #007bff;
  }
  
  /* Dropdown Styles */
  .nav .dropdown {
    position: relative;
    cursor: pointer;
  }
  
  .nav .dropdown-toggle {
    font-weight: 600;
    cursor: pointer;
  }
  
  .nav .dropdown-menu {
    position: absolute;
    top: 100%;
    left: -50px;
    padding: 8px 16px;
    list-style: none;
    display: none;
  }
  
  .nav .dropdown-menu li {
    font-weight: 600;
    padding: 10px;
    background: linear-gradient(to right,#217DE9, black);
  }
  .nav .dropdown-menu li:hover{
    background: linear-gradient(to left,#217DE9, black);
  }
  
  .nav .dropdown-menu a {
    color: #fff;
    display: flex;
    align-items: center;
    gap: 10px;
    text-decoration: none;
  }
  
  .nav .dropdown:hover .dropdown-menu {
    display: block;
  }
  
  /* Fixed Navbar Styles */
  nav.fixed {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    transition: 0.5s;
    background-color: white;
    color: black;
    box-shadow: 0 5px 10px #ccc;
  }
  
  nav.fixed .logo {
    display: none;
  }
  nav.fixed .nav ul li{
    color: #000;
  }
  nav.fixed .nav ul li:hover{
    color: #007bff;
  }
  
  nav.fixed .logo2 {
    display: block;
  }
  .nav .menu-icon {
    display: none;
    cursor: pointer;
  }

  
  @media screen and (max-width: 600px) {
    .nav ul {
      display: none;
    }
  
    .nav .menu-icon {
      display: block;
      cursor: pointer;
    }
  
    .nav .menu-icon i {
      color: #fff;
      font-size: 24px;
    }
    nav.fixed .nav .menu-icon i{
      color: #000;
    }
    .nav ul.show {
      display: flex;
      flex-direction: column;
      position: absolute;
      top: 100%;
      left: 0;
      background-color: #7e7e7e;
      width: 100%;
      z-index: 999;
      text-align: center;
    }
  
    .nav ul.show li {
      padding: 10px;
    }
  }
