.carousel-container {
  overflow: hidden;
  width: 90%;
  margin: 0 auto;
  position: relative;
  color: white;
}
.carousell {
  width: 100%;
  overflow: hidden;
  background: linear-gradient(100deg, #094994, #fff);
}
.carusel {
  width: 90%;
  max-width: 1440px;
  padding: 70px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
}
.cont-show {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  gap: 3rem;
  padding-top: 100px;
}
.carousel-item-content {
  position: relative;
  width: 40%;
  height: 60vh;
  display: flex;
}
.carousel-item-content img {
  position: absolute;
  left: 15%;
  bottom: 10%;
  width: 300px;
  height: 400px;
  opacity: 0.5;
}
.carousel-item-content h3 {
  left: 5%;
  position: absolute;
  font-size: 4.5rem;
  width: 500px;
  margin: 0 auto;
  margin-top: 100px;
  z-index: 5;
  text-align: center;
}
.img {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 400px;
  height: auto;
  z-index: 2;
  opacity: 1;
}
.carousel-item {
  width: 100%;
  display: none;
  opacity: 0;
  transition: opacity 0.5s ease;
}

.carousel-item.active {
  display: block;
  opacity: 1;
}
.cont-img {
  margin-right: 100px;
  position: relative;
  height: 450px;
}
.cont-img::before {
  content: "";
  position: absolute;
  width: 250px;
  height: 430px;
  bottom: 0px;
  left: -450px;
  background-color: rgba(9, 74, 149, 19%);
  z-index: 3;
  animation-name: rotateBefore;
}
.cont-img::after {
  content: "";
  position: absolute;
  width: 200px;
  height: 330px;
  bottom: 0px;
  right: -40px;
  background-color: rgba(10, 47, 89, 19%);
  z-index: 1;
  animation-name: rotateAfter;
}

.cont-img::before,
.cont-img::after {
  animation-duration: 3s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: inherit;
}

@keyframes rotateBefore {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0%);
  }
}

@keyframes rotateAfter {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(00%);
  }
}

@media (max-width: 1190px) {
  .cont-img::before {
    width: 200px;
    left: -350px;
    height: 300px;
  }
  .cont-img::after {
    width: 150px;
    height: 270px;
  }
  .img {
    width: 300px;
  }
  .carusel {
    padding: 10px 0;
  }
  .carousel-item-content h3 {
    font-size: 2.5rem;
    width: 400px;
  }
  .carousel-item-content img {
    left: 35%;
    width: 200px;
    height: 270px;
  }
  .cont-show {
    padding-top: 30px;
  }
  .cont-img{
    margin-right: 50px;
  }
}

@media (max-width: 890px) {
  .cont-img::before {
    width: 150px;
    left: -300px;
    height: 250px;
  }
  .cont-img::after {
    width: 120px;
    height: 220px;
  }
  .img {
    width: 250px;
  }
  .carousel-item-content h3 {
    font-size: 1.5rem;
    padding-top: 50px;
    left: 0;
    width: 200px;
  }
  .carousel-item-content img {
    left: 0;
    width: 200px;
    height: 270px;
  }
  .cont-show {
    padding-top: 0px;
  }
  .cont-img{
    z-index: 155;
    margin-right: 30px;
  }
}
@media (max-width: 670px) {
  .cont-show{
    flex-direction: column;
    gap: 10px;
    padding: 0; 
  }
  .cont-img::before {
    width: 150px;
    left: -300px;
    height: 250px;
  }
  .cont-img::after {
    width: 120px;
    height: 220px;
  }
  .img {
    width: 250px;
  }
  .carousel-item-content h3 {
    font-size: 2rem;
    left: 25%;
    width: 250px;
  }
  .carousel-item-content{
    width: 100%;
    padding: 0;
  }
  .carousel-item-content img {
    left: 30%;
    width: 200px;
    height: 270px;
    margin: 0 auto;
  }
  .cont-img{
    margin: 0 auto;
    padding: 0;
    margin-right: 100px;
  }
}
@media (max-width: 450px){
  .cont-img{
    margin: 0 auto;
    margin-right: 00px;
  }
  .carousel-item-content img {
    left: 15%;
    width: 200px;
    height: 270px;
    margin: 0 auto;
  }
  .carousel-item-content h3 {
    font-size: 2rem;
    left: 10%;
    width: 250px;
  }
}
@media (max-width: 350px){
  .cont-img{
    margin: 0 auto;
    margin-right: 00px;
  }
  .carousel-item-content img {
    left: 15%;
    width: 200px;
    height: 270px;
    margin: 0 auto;
  }
  .carousel-item-content h3 {
    font-size: 1.5rem;
    left: 0%;
    width: 200px;
  }
}