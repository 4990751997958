.HAbout{
    text-align: center;
    padding-bottom: 20px;
}
.HAbout-content{
    padding-top: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
}
.HAbout-left{
    width: 45%;
}
.HAbout-right{
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
    text-align: left;
    width: 40%;
}

@media (max-width:1200px){
    .HAbout-right p{
        font-size: 12px;
        line-height: 20px;
    }
}
@media (max-width:700px){
    .HAbout-content{
        flex-direction: column;
    }
    .HAbout-left{
        width: 100%;
    }
    .HAbout-right{
        width: 100%;
        text-align: center;
    }
    .HAbout-right button{
        margin: 0 auto;
    }
    .HAbout-right h2{
        font-size: 12px;
    }
    .HAbout-right p{
        font-size: 8px;
        line-height: 20px;
    }
}